var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar-wrapper"},[_c('div',{staticClass:"inner"},[_c('Avatar',{attrs:{"img":_vm.imageLocal}}),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"action-buttons"},[_c('div',{staticClass:"action-item"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.loading,"id":"pick-avatar","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-upload ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.imageLocal ? 'Change' : 'Add a')+" photo")])])],1),(_vm.imageLocal)?_c('v-dialog',{staticClass:"action-item",attrs:{"width":"400"},on:{"click:outside":_vm.onDialogDeleteClose},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('div',{staticClass:"card__action"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"fab":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove photo")])])],1)]}}],null,false,3791820665),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 mb-4"},[_c('strong',[_vm._v("Are you sure you want to delete this photo?")])]),_c('v-card-actions',{staticClass:"popup__actions"},[_c('v-btn',{attrs:{"type":"button"},on:{"click":_vm.onDialogDeleteClose}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"type":"submit","color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.deleteAvatar($event)}}},[_vm._v(" Yes ")])],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"action-responses"},[(_vm.loading)?_c('Loader'):_vm._e()],1)])],1),_c('avatar-cropper',{attrs:{"labels":{ submit: 'Submit', cancel: 'Cancel'},"trigger":"#pick-avatar","upload-form-data":{ token_id: this.id },"upload-form-name":"image_url","output-options":{maxWidth: 500},"upload-headers":{ 'Authorization': ("Bearer " + (_vm.auth.login.token))},"upload-url":_vm.api,"mimes":"image/png, image/jpeg"},on:{"uploading":_vm.handleUploading,"uploaded":_vm.handleUploaded,"error":_vm.handlerError}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }