<template>
	<div class="avatar-wrapper">

		<div class="inner">
			<Avatar :img="imageLocal" />
			<div class="actions">
				<div class="action-buttons">
					<div class="action-item">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn :disabled="loading" id="pick-avatar" fab v-bind="attrs" v-on="on">
									<v-icon color="success">
										mdi-upload
									</v-icon>
								</v-btn>
							</template>
							<span>{{ imageLocal ? 'Change' : 'Add a' }} photo</span>
						</v-tooltip>
					</div>

					<v-dialog
							v-if="imageLocal"
							class="action-item"
							v-model="dialogDelete"
							width="400"
							@click:outside="onDialogDeleteClose"
					>
						<template #activator="{ on: dialog }">
							<div class="card__action">
								<v-tooltip bottom>
									<template #activator="{ on: tooltip }">
										<v-btn :disabled="loading" fab v-on="{ ...dialog, ...tooltip }">
											<v-icon color="error">
												mdi-delete
											</v-icon>
										</v-btn>
									</template>
									<span>Remove photo</span>
								</v-tooltip>
							</div>
						</template>

						<v-card>
							<v-card-title class="headline grey lighten-2 mb-4">
								<strong>Are you sure you want to delete this photo?</strong>
							</v-card-title>

							<v-card-actions class="popup__actions">
								<v-btn
										type="button"
										@click="onDialogDeleteClose"
								>
									No
								</v-btn>
								<v-btn
										type="submit"
										@click.prevent="deleteAvatar"
										color="error"
								>
									Yes
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

				</div>

				<div class="action-responses">
					<Loader v-if="loading" />
				</div>

			</div>
		</div>

		<avatar-cropper
				@uploading="handleUploading"
				@uploaded="handleUploaded"
				@error="handlerError"
				:labels="{ submit: 'Submit', cancel: 'Cancel'}"
				trigger="#pick-avatar"
				:upload-form-data="{ token_id: this.id }"
				upload-form-name="image_url"
				:output-options="{maxWidth: 500}"
				:upload-headers="{ 'Authorization': `Bearer ${auth.login.token}`}"
				:upload-url="api"
				mimes="image/png, image/jpeg"
		/>
	</div>
</template>

<script>
	import AvatarCropper from "vue-avatar-cropper";
	import Loader from "../common/Loader";
	import {
		API_AVATAR_DELETE,
		API_AVATAR_UPLOAD
	} from "../../../endpoints";
	import {
		mapActions,
		mapState
	} from "vuex";
	import service from "../../../service";
	import { ADD_TOAST_MESSAGE } from "vuex-toast";
	import Avatar from "../common/Avatar";


	export default {
		name: "FormAvatar",
		components: { Avatar, Loader, AvatarCropper },
		props: {
			id: {
				type: String,
				required: true,
			},
			image: {
				type: String,
				required: true,
			},
		},
		computed: {
			...mapState( [
				'auth'
			] ),
		},
		data() {
			return {
				api: `${ process.env.VUE_APP_API_URL }${ API_AVATAR_UPLOAD }`,
				loading: false,
				imageLocal: this.image,
				dialogDelete: false,
			};
		},
		methods: {
			...mapActions( {
				addToast: ADD_TOAST_MESSAGE
			} ),
			sendNotification( text, type ) {
				this.addToast( {
					text,
					type,
				} );
			},
			handleUploading() {
				this.loading = true;
			},
			handleUploaded( response ) {
				this.loading = false;
				this.imageLocal = response.image_url;
				this.sendNotification( 'Image has been successfully uploaded', 'success' );
			},
			handlerError( message ) {
				this.loading = false;
				this.sendNotification( message, 'danger' );
			},
			onDialogDeleteClose() {
				this.dialogDelete = false;
			},
			async deleteAvatar() {
				this.loading = true;
				try {
					await service.post( API_AVATAR_DELETE, { token_id: this.id } );
					this.imageLocal = '';
					this.sendNotification( 'Image has been successfully deleted', 'success' );
				} catch ( e ) {
					this.sendNotification( 'Something went wrong. Please try later.', 'danger' );
				} finally {
					this.loading = false;
					this.onDialogDeleteClose();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.avatar-wrapper {
		margin-bottom: 5rem;
	}

	.inner {
		display: flex;
		justify-content: space-between;
		@include breakpoint(overPhone) {
			justify-content: flex-start;
		}
	}

	.actions {
		flex: 1;
	}

	.action-buttons {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		margin-bottom: 2rem;
		@include breakpoint(overPhone) {
			flex-direction: row;
			align-items: center;
		}
	}

	.action-responses {
		display: flex;
		justify-content: flex-end;
		padding-right: 1rem;
		@include breakpoint(overPhone) {
			justify-content: flex-start;
			padding-left: 1rem;
		}
	}

	.action-item {
		margin-bottom: 1.5rem;

		@include breakpoint(overPhone) {
			margin-right: 1.5rem;
			margin-bottom: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
</style>

<style lang="scss">
	.avatar-cropper-btn {
		font-size: 1.6rem !important;
		text-transform: uppercase !important;
		font-weight: $fw-bold !important;
		transition: $transition !important;
		padding: 2rem 0 !important;
		color: $c-text-primary !important;

		&:hover {
			background-color: $c-brand-primary !important;
			color: $c-white !important;
		}
	}
</style>
