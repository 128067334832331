<template>
	<div>
		<TagFormHuman v-if="tagType === TAG_TYPE_HUMAN" :data="this.data" />
		<TagFormAnimal v-if="tagType === TAG_TYPE_ANIMALS" :data="this.data" />
	</div>
</template>

<script>
	import { mixinTagTypes } from "../../mixins/mixinTagTypes";
	import TagFormHuman from "./TagFormHuman";
	import TagFormAnimal from "./TagFormAnimal";


	export default {
		name: "TagForm",
		components: { TagFormAnimal, TagFormHuman },
		props: {
			data: {
				type: Object,
				required: true,
			}
		},
		mixins: [
			mixinTagTypes,
		],
		data() {
			return {
				tagType: this.data.type_name,
			};
		},
	};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
	.tag-form {
		margin-top: 5rem;
		@include breakpoint(overPhone) {
			margin-top: 12rem;
		}

		.section_title {
			font-size: 2rem;
			margin-bottom: 2rem;
			color: $c-black;
			@include breakpoint(overPhone) {
				margin-bottom: 4rem;
			}
		}

		.grid-page {
			background-size: contain;
			background-position: 0 20%;
			margin-bottom: 2rem;
			@include breakpoint(desktop) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 15%;
				margin-bottom: 8rem;
				background-position: 0 50%;
				background-size: auto;
			}
		}

		.column {
			@include breakpoint(toDesktop) {
				margin-bottom: 5rem;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.v-input {
			margin-bottom: 1rem;
		}
	}
</style>
