export const NETWORKS_DATA = {
	facebook: {
		prefix: 'https://facebook.com/',
		hint: 'Insert your Facebook username',
		color: '#3696f5',
	},
	twitter: {
		prefix: 'https://twitter.com/',
		hint: 'Insert your Twitter username',
		color: '#50abf1',
	},
	instagram: {
		prefix: 'https://www.instagram.com/',
		hint: 'Insert your Instagram username',
		color: '#d037a5',
	},
	linkedin: {
		prefix: 'https://www.linkedin.com/in/',
		hint: 'Insert your Linkedin username',
		color: '#2877b5',
	},
	snapchat: {
		prefix: 'https://www.snapchat.com/add/',
		hint: 'Insert your Snapchat username',
		color: '#eee830',
	},
	tiktok: {
		prefix: 'https://www.tiktok.com/@',
		hint: 'Insert your TikTok username',
		color: '#000000',
		customIcon: true,
	},
	pinterest: {
		prefix: 'https://www.pinterest.com/',
		hint: 'Insert your Pinterest username',
		color: '#e60023',
	},
	twitch: {
		prefix: 'https://www.twitch.tv/',
		hint: 'Insert your Twitch username',
		color: '#9147ff',
	},
	soundcloud: {
		prefix: 'https://soundcloud.com/',
		hint: 'Insert your Soundcloud username',
		color: '#f55a11',
	},
	youtube: {
		label: 'Link to your Youtube account',
		color: '#f40104',
		validation: [
			'protocol',
		],
	},
	vimeo: {
		label: 'Link to your Vimeo account',
		color: '#1ab7ea',
		validation: [
			'protocol',
		],
	},
	spotify: {
		label: 'Full Link to your Spotify account',
		color: '#45d75f',
		validation: [
			'protocol',
		],
	},
	booksy: {
		label: 'Full Link to your Booksy account',
		color: '#42c9a2',
		validation: [
			'protocol',
		],
		customIcon: true,
	},
	tripadvisor: {
		label: 'Full Link to your Tripadvisor account',
		color: '#4ae0a1',
		validation: [
			'protocol',
		],
		customIcon: true,
	},
	discord: {
		label: 'Full Link to your Discord account',
		color: '#7388da',
		validation: [
			'protocol',
		],
	},
};
