<template>
	<figure class="avatar">
		<img
				v-if="img"
				:src="img"
				alt="Avatar"
		/>
	</figure>
</template>

<script>
	export default {
		name: "Avatar",
		props: {
			img: {
				type: [
					String,
					undefined
				],
				required: true,
			}
		},
	};
</script>

<style scoped lang="scss">
	.avatar {
		background-color: $c-gray-primary;
		width: 22.5rem;
		height: 22.5rem;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 1.5rem;

		@include breakpoint(overPhone) {
			width: 25rem;
			height: 25rem;
		}

		img {
			width: 100%;
		}
	}
</style>
