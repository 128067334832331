<template>
	<div>
		<header class="tag-single-header">
			<div class="go-back">
				<router-link :to="paths().home">Dashboard</router-link>
				<span>/ <b>Edit</b></span>
			</div>
			<Title class="page-title" title="Edit" />
			<div class="page-actions">
				<div class="page-actions__action">
					<v-btn
							type="submit"
							large
							outlined
							class="btn-submit"
							:loading="tag.singleDataUpdate.isLoading"
							@click.prevent="onSubmit"
					>
						Save and Preview
					</v-btn>
				</div>
			</div>
		</header>

		<v-form
				ref="form"
				class="tag-form"
		>
			<div class="grid-page">
				<div class="column">
					<h3 class="section_title">Basic info</h3>

					<FormAvatar
							v-if="this.data.token_id"
							:id="this.data.token_id"
							:image="this.data.image_url || ''"
					/>

					<v-text-field
							name="firstName"
							v-model="firstName"
							label="First Name"
							color="none"
							prepend-icon="mdi-account"
					></v-text-field>
					<v-text-field
							name="lastName"
							v-model="lastName"
							label="Last Name"
							color="none"
							prepend-icon="mdi-account"
					></v-text-field>
					<v-text-field
							name="address"
							v-model="address"
							label="Address"
							color="none"
							prepend-icon="mdi-home"
					></v-text-field>
					<v-text-field
							name="city"
							v-model="city"
							label="City"
							color="none"
							prepend-icon="mdi-city"
					></v-text-field>
					<v-text-field
							name="country"
							v-model="country"
							label="Country"
							color="none"
							prepend-icon="mdi-earth"
					></v-text-field>
					<v-text-field
							name="phone"
							v-model="phone"
							label="Phone"
							color="none"
							prepend-icon="mdi-phone"
							:rules="[
								validationRules.phone,
							]"
					></v-text-field>
					<v-text-field
							name="email"
							class="input-lowercase"
							v-model="email"
							label="Email"
							color="none"
							prepend-icon="mdi-email"
							:rules="[
								validationRules.email,
							]"
					></v-text-field>
					<v-text-field
							name="email2"
							class="input-lowercase"
							v-model="email2"
							label="Email 2nd"
							color="none"
							prepend-icon="mdi-email"
							:rules="[
								validationRules.email,
							]"
					></v-text-field>
					<v-text-field
							name="website"
							v-model="website"
							label="Website"
							color="none"
							prepend-icon="mdi-web"
							:rules="[
								validationRules.protocol,
							]"
					></v-text-field>
					<v-text-field
							name="website2"
							v-model="website2"
							label="Website 2nd"
							color="none"
							prepend-icon="mdi-web"
							:rules="[
								validationRules.protocol,
							]"
					></v-text-field>

					<v-textarea
							name="description"
							v-model="description"
							label="Description / Biography"
							color="none"
							rows="6"
							prepend-icon="mdi-text-account"
					></v-textarea>
				</div>

				<div class="column">
					<h3 class="section_title">Social Networks</h3>

					<v-text-field
							v-for="({ verbose_id }) in networks"
							:key="verbose_id"
							:class="verbose_id"
							:name="verbose_id"
							v-model="networks.find(n => n.verbose_id === verbose_id).value"
							:prefix="networksData[verbose_id] && networksData[verbose_id].prefix"
							:hint="networksData[verbose_id] && networksData[verbose_id].hint"
							:label="networksData[verbose_id] && networksData[verbose_id].label || 'username'"
							:color="networksData[verbose_id] && networksData[verbose_id].color || 'none'"
							:rules="
							networksData[verbose_id]
							&& networksData[verbose_id].validation
							&& networksData[verbose_id].validation.map(i => validationRules[i] )
						"
							@keydown.space.prevent
					>
						<v-icon
								v-if="networksData[verbose_id] && networksData[verbose_id].customIcon"
								slot="prepend"
						>
							{{ `$${verbose_id}` }}
						</v-icon>
						<v-icon
								v-else
								slot="prepend"
								:color="networksData[verbose_id] && networksData[verbose_id].color"
						>
							mdi-{{verbose_id}}
						</v-icon>
					</v-text-field>
				</div>
			</div>

			<v-btn
					type="submit"
					large
					outlined
					class="btn-submit"
					:loading="tag.singleDataUpdate.isLoading"
					@click.prevent="onSubmit"
			>
				Save and Preview
			</v-btn>

		</v-form>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import { mixinValidationRules } from "../../mixins/mixinValidationRules";
	import { NETWORKS_DATA } from "../../constants/networks";
	import FormAvatar from "./FormAvatar";
	import { mixinTagTypes } from "../../mixins/mixinTagTypes";
	import { mixinPaths } from "../../mixins/mixinPaths";
	import { mixinBrandName } from "../../mixins/mixinBrandName";
		import Title from "../common/Title";
		import paths from "../../constants/paths";


	export default {
		name: "TagFormHuman",
		components: { FormAvatar, Title },
		props: {
			data: {
				type: Object,
				required: true,
			}
		},
		mixins: [
			mixinValidationRules,
			mixinTagTypes,
			mixinPaths,
			mixinBrandName,
		],
		data() {
			return {
				networksData: NETWORKS_DATA,
				tagType: this.data.type_name,

				firstName: this.data.first_name,
				lastName: this.data.last_name,
				address: this.data.address,
				city: this.data.city,
				country: this.data.country,
				phone: this.data.phone,
				email: this.data.email,
				email2: this.data.email2,
				website: this.data.website,
				website2: this.data.website2,
				description: this.data.description,
				networks: [
					...this.data.networks.map( n => ( {
						...n
					} ) )
				],
			};
		},
		computed: {
			...mapState( [
				'tag'
			] ),
		},
		methods: {
			onSubmit() {
				const finalData = {
					token_id: this.data.token_id,
					first_name: this.firstName,
					last_name: this.lastName,
					address: this.address,
					city: this.city,
					country: this.country,
					email: this.email ? this.email.toLowerCase() : this.email,
					email2: this.email2 ? this.email2.toLowerCase() : this.email2,
					website: this.website,
					website2: this.website2,
					phone: this.phone,
					description: this.description,
					networks: this.networks,
				};

				if ( this.$refs.form.validate() ) {
					this.$store.dispatch( "requestUpdateSingleTag", {
						data: finalData,
						redirectOnSuccess: paths.dynamic.tagPreview(this.data.code),
					});
				}
			}
		},
	};
</script>
