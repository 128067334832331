<template>
	<Page class="page-tag-edit">

		<Loader v-if="tag.singleDataGet.isLoading" />
		<Error v-if="tag.singleDataGet.error" />

		<TagForm
				v-if="tag.singleDataGet.data"
				:data="tag.singleDataGet.data"
		/>

	</Page>
</template>

<script>
	import Page from "../../components/layouts/LayoutPage";
	import { mixinPaths } from "../../mixins/mixinPaths";
	import Error from "../../components/common/Error";
	import Loader from "../../components/common/Loader";
	import { mapState } from "vuex";
	import TagForm from "../../components/Tag/TagForm";
	import { mixinBrandName } from "../../mixins/mixinBrandName";


	export default {
		name: 'TagEdit',
		components: { TagForm, Page, Error, Loader },
		data() {
			return {
				id: this.$route.params.id,
			};
		},
		mixins: [
			mixinPaths,
			mixinBrandName,
		],
		computed: {
			...mapState( [
				'tag'
			] ),
		},
		created() {
			this.$store.dispatch( "requestGetSingleTag", this.id );
		},
	};
</script>

<style lang="scss" scoped>
	.page-tag-edit {
		margin-bottom: 6rem;
	}
</style>
