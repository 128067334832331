<template>
	<div>
		<header class="tag-single-header">
			<div class="go-back">
				<router-link :to="paths().home">Dashboard</router-link>
				<span>/ <b>Edit</b></span>
			</div>
			<Title class="page-title" title="Edit" />
			<div class="page-actions">
				<div class="page-actions__action">
					<v-btn
							type="submit"
							large
							outlined
							class="btn-submit"
							:loading="tag.singleDataUpdate.isLoading"
							@click.prevent="onSubmit"
					>
						Save and Preview
					</v-btn>
				</div>
			</div>
		</header>

		<v-form
				ref="form"
				class="tag-form"
		>
			<div
					class="grid-page"
					:style="{'background-image': `url(${require('../../assets/images/paw.png')})`}"
			>
				<div class="column">
					<h3 class="section_title">Basic info</h3>

					<FormAvatar
							v-if="this.data.token_id"
							:id="this.data.token_id"
							:image="this.data.image_url || ''"
					/>
					<v-text-field
							name="petName"
							v-model="petName"
							label="Pet Name"
							color="none"
							prepend-icon="mdi-paw"
					></v-text-field>
					<v-text-field
							name="ownerName"
							v-model="ownerName"
							label="Owner Name"
							color="none"
							prepend-icon="mdi-account"
					></v-text-field>
					<v-text-field
							name="address"
							v-model="address"
							label="Address"
							color="none"
							prepend-icon="mdi-home"
					></v-text-field>
					<v-text-field
							name="city"
							v-model="city"
							label="City"
							color="none"
							prepend-icon="mdi-city"
					></v-text-field>
					<v-text-field
							name="country"
							v-model="country"
							label="Country"
							color="none"
							prepend-icon="mdi-earth"
					></v-text-field>
					<v-text-field
							name="phone"
							v-model="phone"
							label="Owner Phone"
							color="none"
							prepend-icon="mdi-phone"
							:rules="[
								validationRules.phone,
							]"
					></v-text-field>
					<v-text-field
							name="email"
							v-model="email"
							label="Owner Email"
							class="input-lowercase"
							color="none"
							prepend-icon="mdi-email"
							:rules="[
								validationRules.email,
							]"
					></v-text-field>
					<v-textarea
							name="description"
							v-model="description"
							label="Description / Biography"
							color="none"
							rows="6"
							prepend-icon="mdi-text-account"
					></v-textarea>
				</div>

				<div class="column">
					<h3 class="section_title">Social Networks</h3>

					<v-text-field
							v-for="({ verbose_id }) in networks"
							:key="verbose_id"
							:class="verbose_id"
							:name="verbose_id"
							v-model="networks.find(n => n.verbose_id === verbose_id).value"
							:prefix="networksData[verbose_id] && networksData[verbose_id].prefix"
							:hint="networksData[verbose_id] && networksData[verbose_id].hint"
							:label="networksData[verbose_id] && networksData[verbose_id].label || 'username'"
							:color="networksData[verbose_id] && networksData[verbose_id].color || 'none'"
							:rules="
							networksData[verbose_id]
							&& networksData[verbose_id].validation
							&& networksData[verbose_id].validation.map(i => validationRules[i] )
						"
							@keydown.space.prevent
					>
						<v-icon
								v-if="networksData[verbose_id] && networksData[verbose_id].customIcon"
								slot="prepend"
						>
							{{ `$${verbose_id}` }}
						</v-icon>
						<v-icon
								v-else
								slot="prepend"
								:color="networksData[verbose_id] && networksData[verbose_id].color"
						>
							mdi-{{verbose_id}}
						</v-icon>
					</v-text-field>
				</div>
			</div>

			<v-btn
					type="submit"
					large
					outlined
					class="btn-submit"
					:loading="tag.singleDataUpdate.isLoading"
					@click.prevent="onSubmit"
			>
				Save and Preview
			</v-btn>

		</v-form>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import { mixinValidationRules } from "../../mixins/mixinValidationRules";
	import FormAvatar from "./FormAvatar";
	import paths from "../../constants/paths";
	import { mixinPaths } from "../../mixins/mixinPaths";
	import { mixinBrandName } from "../../mixins/mixinBrandName";
	import Title from "../common/Title";
	import { NETWORKS_DATA } from "../../constants/networks";


	export default {
		name: "TagFormAnimal",
		components: { FormAvatar, Title, },
		props: {
			data: {
				type: Object,
				required: true,
			}
		},
		mixins: [
			mixinValidationRules,
			mixinPaths,
			mixinBrandName,
		],
		data() {
			return {
				networksData: NETWORKS_DATA,
				networks: [
					...this.data.networks.filter( n => {
						if (
							n.verbose_id === 'facebook'
							|| n.verbose_id === 'instagram'
						) {
							return {
								...n
							};
						}
					} )
				],

				petName: this.data.animal_name,
				ownerName: this.data.owner_name,
				address: this.data.address,
				city: this.data.city,
				country: this.data.country,
				phone: this.data.phone,
				email: this.data.email,
				description: this.data.description,
			};
		},
		computed: {
			...mapState( [
				'tag'
			] ),
		},
		methods: {
			onSubmit() {
				const finalData = {
					token_id: this.data.token_id,

					animal_name: this.petName,
					owner_name: this.ownerName,
					address: this.address,
					city: this.city,
					country: this.country,
					phone: this.phone,
					email: this.email ? this.email.toLowerCase() : this.email,
					description: this.description,

					networks: this.data.networks, // Required on BE
				};

				if ( this.$refs.form.validate() ) {
					this.$store.dispatch( "requestUpdateSingleTag", {
						data: finalData,
						redirectOnSuccess: paths.dynamic.tagPreview( this.data.code ),
					} );
				}
			}
		}
	};
</script>
